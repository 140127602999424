import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty} from 'lodash'
import classNames from 'classnames'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Table from 'components/Table'
import {accessIdSelector, appIdSelector, isTourEnabledSelector} from 'modules/Login/selectors/user'
import {expiratedSelector} from './selectors/expirated'
import {fetchExpirated} from './actions'
import {MTableToolbar} from 'material-table'
import {Button, Card, CardContent, CardHeader, Grid, Avatar} from '@material-ui/core'
import Modal from './Modal'
import StudentList from './components/studentListNegotiation'
import PaymentOptions, {PAYMENT_OPTIONS} from './Modal/components/paymentOptions'
import useStyle from './style'
import {apiResetAction} from 'utils/API/actions/status'
import { Info } from "@material-ui/icons";
import {EXPIRATED_FINANCIALS, REGISTRATION_EXPIRED} from './constants'
import Loading from 'components/loading'
import CustomCellHighlight from 'components/CustomCellHighlight'
import {FormattedMessage, injectIntl} from 'react-intl'
import {isTourEnabledPaymentSelector} from './selectors/payment'
import csatList from "../../../utils/csatList";
import useCsat from "../../../hooks/use-csat";
import Csat from "../../../components/Csat/Csat";
import Alert from '@material-ui/lab/Alert';
import {getFinancialAgreementPending} from "./services/financial";
import {fetchSelectedExpirated, setRegistrationExpiredSelected} from 'modules/Negotiation/Finance/actions';
import {
  financialAgreementPendingDataSelector, 
  hasLoadedFinancialAgreementPendingSelector, 
  isLoadingFinancialAgreementPendingSelector
} from './selectors/financialAgreementPending';

export const STYLE_CELLS = {
  paddingRight: 20,
  paddingLeft: 0
}

const smColumns = [
  { title: 'Mês/Ano', field: 'yearMonthFormatted', cellStyle: STYLE_CELLS },
  { title: 'Vencimento', field: 'expirationdateFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => Date.parse(param1.expirationdate) - Date.parse(param2.expirationdate) }
]

const otherColumns = [
  { title: 'Fatura', field: 'invoice', cellStyle: STYLE_CELLS },
  { title: 'Valor no Vencimento', field: 'documentvalueFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.documentvalue) - parseFloat(param2.documentvalue) },
  // { title: 'Valor Corrigido', field: 'correctvalueFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.correctvalue) - parseFloat(param2.correctvalue) }
]

const otherMediumColumns = [
  { title: 'Aluno', field: 'studentnameFormatted', cellStyle: STYLE_CELLS },
]

export default injectIntl(({ intl: { messages } }) => {
  const classes = useStyle()
  const tableRef = useRef()
  const dispatch = useDispatch()
  const financialAgreementPending = useSelector(financialAgreementPendingDataSelector)
  const hasLoadedFinancialAgreement = useSelector(hasLoadedFinancialAgreementPendingSelector)
  const isLoadingFinancialAgreement = useSelector(isLoadingFinancialAgreementPendingSelector)

  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  const isEnableTourModal = useSelector(isTourEnabledPaymentSelector)

  const { data, dataSelected, loading, error } = useSelector(expiratedSelector)

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))

  const [valueOption, setValueOption] = React.useState(PAYMENT_OPTIONS.CARD)
  const [isFirst, setIsFirst] = useState(true)
  const [openModalBilletPay, setOpenModalBilletPay] = useState(false)

  const title = messages['finance-negotiation-table-title']
  const isBillet = valueOption === PAYMENT_OPTIONS.BILLET

  // const unidade = useRecoilValue(unidadeGetResponseState);
  //
  // if (unidade && unidade.id === "c31ffd9a-1d4f-ed11-bba3-000d3a88f03a") {
  //   return (
  //     <Alert severity="warning">
  //       Portal financeiro bloqueado temporariamente. Por favor, tente novamente mais tarde.
  //     </Alert>
  //   )
  // }

  const {
    addAnsweredCsat,
    isCsatVisible,
    handleShowCsat
  } = useCsat();

  useEffect(() => {
    if (isTourOpen) {
      return
    }
    if (isFirst) {
      setIsFirst(false)
      dispatch(apiResetAction(EXPIRATED_FINANCIALS))
      dispatch(apiResetAction(REGISTRATION_EXPIRED))
    }
    if (dataSelected && dataSelected.length && !hasLoadedFinancialAgreement && !isLoadingFinancialAgreement) {
        dispatch(getFinancialAgreementPending(appid, dataSelected[0].dataareaid, dataSelected[0].accountresponsible, dataSelected[0].registrationid))
    }
    if (valueOption && tableRef.current) {
      tableRef.current.onAllSelected(true)
    }
  }, [appid, accessId, dispatch, isFirst, valueOption, data, dataSelected, isTourOpen, financialAgreementPending])

  useEffect(() => {
    if (isTourOpen && !isEmpty(data)) {
      return
    }
    dispatch(fetchExpirated({ accessId }))
    setValueOption('')
  }, [accessId, dispatch, appid, isTourOpen])



  const openModal = () => {
    setOpenModalBilletPay(true)
  }

  const changeOptions = (value) => {
    setValueOption(value)
    //tableRef.current.onSearchChange('')
  }

  const actions = (props) => {
    return (
      <div>
        <MTableToolbar {...props} />
        <Grid container>
          <Grid item className={classes.negociationOptions} xs={6} >
            <PaymentOptions valueOption={valueOption} setValueOption={changeOptions} />
          </Grid>
          <Grid item xs={6} className={classNames(classes.negociationOptions, classes.negociationGridButton)} >
            <Button
              data-tut='tur-do-negotiation'
              className={classes.negociationButton}
              disabled={tableRef.current && tableRef.current.state.selectedCount === 0}
              variant='contained'
              color='primary'
              id='btn-pay-billet'
              onClick={() => openModal()}>
              <FormattedMessage id='finance-negotiation-button-do-term' />
            </Button>
          </Grid>
        </Grid>
      </div >
    )
  }

  const columns = () => {
    return [
      ...smColumns,
      ...(isSmall ? [] : otherColumns),
      ...(isMedium ? [] : otherMediumColumns)
    ]
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  if (!isEmpty(error) || !dataSelected) {
    return (
      <Alert severity="error">
        {error.message}
      </Alert>
    )
  }

  const handleCloseCsat = () => {
    const body = document.querySelector('body')
    if (body) body.classList.remove('csatBlockScroll')
    addAnsweredCsat(csatList.acordo)
  }

  const selectStudent = (student) => {
    dispatch(getFinancialAgreementPending(appid, dataSelected[0].dataareaid, dataSelected[0].accountresponsible, student.registrationId))
    dispatch(fetchSelectedExpirated(student.studentName, student.unityId))
    dispatch(setRegistrationExpiredSelected(student))
  }

  return (
    <div>
      <Modal
        open={openModalBilletPay || isEnableTourModal}
        onClose={() => {
          setOpenModalBilletPay(false)
          handleShowCsat(csatList.acordo)
        }}
        isBillet={isBillet}
        data={tableRef.current && tableRef.current.state.originalData}
      />

      <Csat
        show={isCsatVisible(csatList.acordo)}
        scoreTitle="O quanto você ficou satisfeito com: Acordo?"
        onSubmit={handleCloseCsat}
        onClosed={handleCloseCsat}
      />

      <Card>
        <CardHeader title={messages['finance-negotiation-title-registration']} >
        </CardHeader>
        <CardContent >
          <StudentList
            selectRegistration={selectStudent}
          />
        </CardContent>
      </Card>
      <br />
      {financialAgreementPending?.processStatus ?
      <Card
        elevation={1}
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
        }}
      >
        <CardHeader
          title={"Já existe uma solicitação de acordo em andamento!"}
          subheader={financialAgreementPending?.message}
          avatar={
            <Avatar sx={{ bgcolor: "#bdbdbd38" }} aria-label="success icon">
              <Info color='primary'/>
            </Avatar>
          }
        />
      </Card>
      : 
      <div data-tut='tur-table-negotiation' >
        <Table
          isLoading={loading || isLoadingFinancialAgreement}
          tableRef={tableRef}
          components={{
            Toolbar: actions,
            Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
          }}
          options={{
            showTextRowsSelected: false,
            selectionProps: (rowData) => {
              return {
                disabled: true,
                'data-tut': 'tur-home-check-pay',
                color: 'primary',
                id: 'pay-select-all-' + rowData.tableData.id
              }
            },
            search: true,
            showSelectAllCheckbox: false,
            selection: false,
            paging: false,
            sorting: true,
            headerStyle: {
              fontSize: 15,
              paddingRight: 1
            }
          }}
          columns={columns()}
          data={dataSelected}
          title={title}
        />
      </div>
    }
    </div>)
})
