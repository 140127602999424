import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { GET_FINANCIAL_AGREEMENT_PENDING } from '../constants'
import { RESET_ACCESS } from 'layouts/constants'


export default apiReducer({
    entity: GET_FINANCIAL_AGREEMENT_PENDING,
    resetActions: [USER_CLEAR, RESET_ACCESS],
    defaultData: {},
})
