import Card from "react-credit-cards";
import React, {useState} from "react";
import acceptedCards from "../../utils/acceptedCards";
import PaymentIcon from "../PaymentIcon/PaymentIcon";
import classNames from "../../utils/classNames";
import * as _walletService from "../../service/wallet-service";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import * as formValidacao from "../../commons/functions/form-validacao";
import {useRecoilState, useSetRecoilState} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import {useCardVerification} from "../../hooks/useCardVerification";
import * as mensagem from '../../components/shared/Message.js';
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import {walletCardChangeSubsState} from "../../recoil/atoms/walletCardChangeSubsState";
import {walletCardSubsState} from "../../recoil/atoms/walletCardSubsState";
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import {Col, Row} from "react-bootstrap";

const CardSelection = (props) => {
  const {
    onClick,
    onClickChange,
    card: {
      cardId,
      lastFourDigits = '',
      expirationYear = '',
      expirationMonth = '',
      cardholderName = '',
      brand = '',
      numberToken = null,
      isExpired = false,
      canRemove = false
    },
    buttonLabel = 'Selecionar',
    type = 'card',
    selected = false,
    showButton = true,
    showRemove = true,
  } = props;
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const cardNumber = `**** **** **** ${lastFourDigits}`;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoadingSubs, setIsLoadingSubs] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [subs, setSubs] = useState([]);
  const hasSubs = subs.length > 0;
  const [operation, setSetOperation] = useState("");
  const setShowChangeSubs = useSetRecoilState(walletShowChangeSubsState);
  const setCardChangeSubs = useSetRecoilState(walletCardChangeSubsState);
  const setCardSubs = useSetRecoilState(walletCardSubsState);
  const setReload = useSetRecoilState(walletCardReloadState);
  const operations = {
    delete: "delete",
    select: "select",
    changeSubsCard: "changeSubsCard",
  }
  const {
    month,
    setMonth,
    year,
    setYear,
    isVerifying,
    handleVerifyCard,
  } = useCardVerification({
    cardId,
    handleCardSelection: onClick
  })

  const handleCardSelection = (card) => {
    if (onClick)
      onClick(card);
  }

  const handleCardExclusion = () => {
    if (isDeleting) return;

    setIsDeleting(true);

    const unidadeId = sessionStorage.getItem('@SMO20/unidade_Id');

    _walletService.cartaoApagar(
      unidadeId,
      cardId ?? ""
    ).then(() => {
      let cards = creditCards.filter(c => c.cardId !== cardId);
      setCreditCards(cards);
      setReload(true)
      mensagem.ExibeMensagem(`Cartão final ${lastFourDigits} removido!`, "0")
    }).catch(() => {
      mensagem.ExibeMensagem(`Falha ao remover o cartão final ${lastFourDigits}.`, "2")
    }).finally(() => setIsDeleting(false))
  }

  const handleCancelVerification = () => {
    setShowVerification(false);
    setMonth("");
    setYear("");
  }

  const handleConfirm = () => {
    switch (operation) {
      case operations.delete:
        handleCardExclusion()
        break;
      case operations.select:
        handleVerifyCard();
        break;
      default:
        console.log("operação não implementada")
    }
    setShowVerification(false);
    setMonth("");
    setYear("");
  }

  const handleOperation = op => () => {
    setSetOperation(op)

    if (op === operations.select && onClickChange) {
      onClickChange();
      return;
    }

    if (op === operations.delete) {
      handleLoadSubscriptions()
      return;
    }

    if (op === operations.changeSubsCard) {
      setShowChangeSubs(true);
      setCardSubs(subs);
      setCardChangeSubs(props.card)
      return;
    }

    if (null == numberToken) //Solicita verificação do cartão para recuperar o numberToken
    {
      setShowVerification(true);
    } else if (op === operations.select) {
      handleCardSelection(props.card)
    }
  }

  const handleLoadSubscriptions = () => {
    if (isLoadingSubs) return;

    setIsLoadingSubs(true);

    const unidadeId = sessionStorage.getItem('@SMO20/unidade_Id');

    _walletService.cartaoAssinaturas(
      unidadeId,
      cardId ?? ""
    ).then(async (resp) => {
      if (resp.ok) {
        const json = await resp.json();

        if (json.length) {
          mensagem.ExibeMensagem(`O cartão possui recorrência ativa. Altere antes de remover.`, "1");
          setSubs(json);
        } else {
          setShowVerification(true);
        }
        return;
      }
      mensagem.ExibeMensagem(`Falha ao carregar assinaturas.`, "2")
    }).catch(() => {
      mensagem.ExibeMensagem(`Falha ao carregar assinaturas.`, "2")
    }).finally(() => setIsLoadingSubs(false))
  }

  const handleChangeMonth = (event) => {
    const {value} = event.target;
    if (value.length > 4) {
      event.target.value = value.slice(0, 4);
      return;
    }
    setMonth(value)
  }

  const handleChangeYear = (event) => {
    const {value} = event.target;
    if (value.length > 4) {
      event.target.value = value.slice(0, 4);
      return;
    }
    setYear(value)
  }

  const renderConfirmForm = () => {
    return (
      <form
        onSubmit={handleConfirm}
      >
        <div className='smo-wallet-card-selection-actions-confirmation-container'>
          <div className='smo-wallet-card-selection-actions-confirmation-container-text'>
            <label htmlFor="securityCode">
              {operation === operations.delete ? "Confirme a exclusão do cartão:" : "Confirme a expiração do cartão:"}
            </label>
            {operation !== operations.delete &&
              <>
                <Row>
                  <Col className="m-0 p-0 pr-2">
                    <label htmlFor="expirationMonth">
                      Mês
                    </label>
                    <input
                      type="number"
                      className={classNames(
                        'form-control',
                        (Number(month) < 1 || Number(month) > 12)
                          ? 'validError'
                          : 'validOk',
                      )}
                      maxLength={2}
                      id="expirationMonth"
                      name="expirationMonth"
                      autoComplete="cc-exp-month"
                      min="1"
                      max="12"
                      value={month}
                      placeholder="MM"
                      onChange={handleChangeMonth}
                      required
                    />
                  </Col>

                  <Col className="m-0 p-0">
                    <label htmlFor="expirationYear">
                      Ano
                    </label>
                    <input
                      type="number"
                      className={classNames(
                        'form-control',
                        (Number(year) < 10 || Number(year) > 50)
                          ? 'validError'
                          : 'validOk',
                      )}
                      id="expirationYear"
                      maxLength={2}
                      min="10"
                      max="50"
                      name="expirationYear"
                      autoComplete="cc-exp-year"
                      value={year}
                      placeholder="AA"
                      onChange={handleChangeYear}
                      required
                    />
                  </Col>
                </Row>
              </>
            }
          </div>

          <div className='smo-wallet-card-selection-actions-confirmation-container-actions'>
            <button
              className="btn btn-primary smo-wallet-card-selection-button"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                fill="currentColor"
              >
                <title>confirm</title>
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
              </svg>
            </button>

            <button
              className="btn btn-danger smo-wallet-card-selection-button"
              onClick={handleCancelVerification}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                fill="currentColor"
              >
                <title>cancel</title>
                <path d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className={classNames(
      "smo-wallet-card-selection",
      selected && "smo-wallet-card-selection-selected",
    )}>
      {numberToken && <span className="badge badge-success smo-wallet-card-selection-verified-badge">verificado</span>}
      {isExpired && <span className="badge badge-danger smo-wallet-card-selection-verified-badge">expirado</span>}
      {hasSubs && <span className="badge badge-warning smo-wallet-card-selection-verified-badge">
        recorrência{subs.length > 1 && "s"} ativa{subs.length > 1 && "s"}: {subs.length}
      </span>}
      <div
        className={classNames(
          "smo-wallet-card-selection-container",
          type === 'row' && "smo-wallet-card-selection-container-row"
        )}
      >
        {type === 'row' && (
          <div className="smo-wallet-card-selection-row-card-info-container">
            <div className="smo-wallet-card-selection-row-card-info-brand">
              <div className="smo-wallet-card-selection-avatar">
                <PaymentIcon
                  id={brand}
                  className='payment-icon'
                />
              </div>
            </div>

            <div className="smo-wallet-card-selection-row-card-info">
              <p>{cardNumber}</p>
              <p className="smo-wallet-card-selection-row-card-info-brand">{brand}</p>
            </div>
          </div>
        )}
        <span style={{display: type === 'row' ? "none" : "block"}}>
          <Card
            number={cardNumber}
            name={cardholderName}
            expiry={`${expirationMonth}/${expirationYear}`}
            acceptedCard={acceptedCards}
            preview={true}
            placeholders={{name: 'Nome impresso no cartão'}}
            locale={{valid: 'BR'}}
            issuer={brand}
            focused={showVerification && operation !== operations.delete ? "cvc" : undefined}
          />
        </span>

        <div className="smo-wallet-card-selection-actions">
          {showVerification ? renderConfirmForm() : (
            <>
              {!isExpired && (onClick || onClickChange) &&
                <button
                  className="btn btn-primary smo-wallet-card-selection-button"
                  onClick={handleOperation(operations.select)}
                  disabled={!showButton || isDeleting || isVerifying || isExpired || isLoadingSubs}
                >
                  {isVerifying ? (
                    <LoadingAnimation/>
                  ) : buttonLabel}
                </button>
              }

              {(showRemove && canRemove && !hasSubs) &&
                <button
                  className="btn btn-danger smo-wallet-card-selection-button"
                  onClick={handleOperation(operations.delete)}
                  disabled={!showButton || isDeleting | isLoadingSubs}
                >
                  {isDeleting ? (
                    <LoadingAnimation/>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      fill="currentColor"
                    >
                      <title>delete</title>
                      <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                  )}
                </button>
              }

              {(showRemove && canRemove && hasSubs) &&
                <button
                  className="btn btn-warning smo-wallet-card-selection-button"
                  onClick={handleOperation(operations.changeSubsCard)}
                  disabled={!showButton}
                >
                  Alterar recorrência{subs.length > 1 && "s"}
                </button>
              }
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardSelection;
