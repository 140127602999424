import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from 'utils/API/action-types'

export const CREATE_FINANCIAL = 'CREATE_FINANCIAL'
export const REQUEST_CREATE_FINANCIAL = apiRequestActionType(CREATE_FINANCIAL)
export const SUCCESS_CREATE_FINANCIAL = apiSuccessActionType(CREATE_FINANCIAL)
export const ERROR_CREATE_FINANCIAL = apiErrorActionType(CREATE_FINANCIAL)
export const RESET_CREATE_FINANCIAL = apiResetActionType(CREATE_FINANCIAL)

export const POST_FINANCE = 'POST_FINANCE'
export const REQUEST_POST_FINANCE = apiRequestActionType(POST_FINANCE)
export const SUCCESS_POST_FINANCE = apiSuccessActionType(POST_FINANCE)
export const ERROR_POST_FINANCE = apiErrorActionType(POST_FINANCE)
export const RESET_POST_FINANCE = apiResetActionType(POST_FINANCE)

export const EXPIRATED_FINANCIALS = 'EXPIRATED_FINANCIALS'
export const REQUEST_EXPIRATED_FINANCIALS = apiRequestActionType(EXPIRATED_FINANCIALS)
export const SUCCESS_EXPIRATED_FINANCIALS = apiSuccessActionType(EXPIRATED_FINANCIALS)
export const ERROR_EXPIRATED_FINANCIALS = apiErrorActionType(EXPIRATED_FINANCIALS)
export const RESET_EXPIRATED = apiResetActionType(EXPIRATED_FINANCIALS)
export const SELECTED_EXPIRATED_FINANCIALS = 'SELECTED_EXPIRATED_FINANCIALS'

export const REGISTRATION_EXPIRED = 'REGISTRATION_EXPIRED'
export const REQUEST_REGISTRATION_EXPIRED = apiRequestActionType(REGISTRATION_EXPIRED)
export const SUCCESS_REGISTRATION_EXPIRED = apiSuccessActionType(REGISTRATION_EXPIRED)
export const ERROR_REGISTRATION_EXPIRED = apiErrorActionType(REGISTRATION_EXPIRED)
export const RESET_REGISTRATION_EXPIRED = apiResetActionType(REGISTRATION_EXPIRED)
export const SELECTED_REGISTRATION_EXPIRED = 'SELECTED_REGISTRATION_EXPIRED'

export const GET_COMPANY_INFORMATIONS = 'GET_COMPANY_INFORMATIONS'
export const REQUEST_GET_COMPANY_INFORMATIONS = apiRequestActionType(GET_COMPANY_INFORMATIONS)
export const SUCCESS_GET_COMPANY_INFORMATIONS = apiSuccessActionType(GET_COMPANY_INFORMATIONS)
export const ERROR_GET_COMPANY_INFORMATIONS = apiErrorActionType(GET_COMPANY_INFORMATIONS)
export const RESET_GET_COMPANY_INFORMATIONS = apiResetActionType(GET_COMPANY_INFORMATIONS)

export const GET_FINANCIAL_AGREEMENT_PENDING = 'GET_FINANCIAL_AGREEMENT_PENDING'
export const REQUEST_GET_FINANCIAL_AGREEMENT_PENDING = apiRequestActionType(GET_FINANCIAL_AGREEMENT_PENDING)
export const SUCCESS_GET_FINANCIAL_AGREEMENT_PENDING = apiSuccessActionType(GET_FINANCIAL_AGREEMENT_PENDING)
export const ERROR_GET_FINANCIAL_AGREEMENT_PENDING = apiErrorActionType(GET_FINANCIAL_AGREEMENT_PENDING)
export const RESET_GET_FINANCIAL_AGREEMENT_PENDING = apiResetActionType(GET_FINANCIAL_AGREEMENT_PENDING)

export const PAYMENT_CREDIT_NEGOTIATION = 'PAYMENT_CREDIT_NEGOTIATION'

export const ACTIVE_TOUR_MODAL = 'ACTIVE_TOUR_MODAL'

export const STEP_TOUR_MODAL = 'STEP_TOUR_MODAL'