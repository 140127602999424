import { pipe, prop, isEmpty } from 'ramda'

import {
    getAPIDataSelector,
    hasErrorAPISelector,
    getAPIErrorSelector,
    hasLoadedAPISelector,
    isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const financialAgreementPendingSelector = pipe(baseFinanceSelector, prop('financialAgreementPending'))

export const financialAgreementPendingDataSelector = getAPIDataSelector(financialAgreementPendingSelector)

export const hasLoadedFinancialAgreementPendingSelector = hasLoadedAPISelector(financialAgreementPendingSelector)

export const hasErrorFinancialAgreementPendingSelector = hasErrorAPISelector(financialAgreementPendingSelector)

export const mensagemErrorAcordoSelector = getAPIErrorSelector(financialAgreementPendingSelector)

export const isLoadingFinancialAgreementPendingSelector = isLoadingAPISelector(financialAgreementPendingSelector)

export const financialAgreementPendingIdDataSelector = pipe(financialAgreementPendingDataSelector, prop('financialAgreementPendingId'))

export const financialAgreementPendingCustSelector = pipe(financialAgreementPendingDataSelector, prop('listCustTable'))

export const isFetchFinancialPendingSelector = createSelector(
    hasLoadedFinancialAgreementPendingSelector,
    isLoadingFinancialAgreementPendingSelector,
    hasErrorFinancialAgreementPendingSelector,
    financialAgreementPendingDataSelector,
    (hasLoaded, isLoading, hasError, data) => isEmpty(data.list) && !hasLoaded && !isLoading && !hasError)
