import React from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import acceptedCards from "../../utils/acceptedCards";
import Card from "react-credit-cards";
import {Col, Container, Row} from "react-bootstrap";
import classNames from "../../utils/classNames";
import * as formValidacao from "../../commons/functions/form-validacao";
import InputMask from "react-input-mask";
import {BRANDS_CONFIG} from "../../modules/Finance/constants";
import * as _walletService from "../../service/wallet-service";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import * as mensagem from '../../components/shared/Message.js';
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import {matriculasByResponsavelResponseState} from "../../recoil/atoms/matriculasByResponsavelResponseState";
import * as _matricula from "../../service/matricula-service";

const isDuplicatedNewCard = (new_number) => savedCard => savedCard.cardNumber === new_number
const isDuplicatedTokenizedCard = (new_number, values) => savedCard => {
  const tokenizedNumber = `${savedCard.bin}${savedCard.lastFourDigits}`;
  const newCardBin = new_number.slice(0, savedCard.bin.length);
  const newCardLastFour = new_number.slice(new_number.length - 4, new_number.length);
  const newCardNumber = `${newCardBin}${newCardLastFour}`;
  const expirationMonth = values.expirationMonth.padStart(2, '0');
  const expirationYear = values.expirationYear.padStart(2, '0');
  const tokenizedExpiration = `${savedCard.expirationMonth}${savedCard.expirationYear}`;
  const newCardExpiration = `${expirationMonth}${expirationYear}`;

  return tokenizedNumber === newCardNumber && tokenizedExpiration === newCardExpiration;
}

const isTokenizedCard = c => 'bin' in c;

function WalletSaveCard(
  {
    onCancel = () => {},
    onClose = () => {}
  }
) {
  const formRef = React.useRef(null);
  const defaultValues = {
    cardholderName: '',
    cardholderIdentification: sessionStorage.getItem('@SMO20/cpf') ?? '',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
  };
  const [cardValues, setCardValues] = React.useState(defaultValues);
  const [focused, setFocused] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const cardholderIdentification_mask = cardValues.cardholderIdentification.replace(/\D/g, '').length <= 11
    ? "999.999.999-99"
    : "999.999.999/9999-99"
  const [isSaving, setIsSaving] = React.useState(false);
  const setReload = useSetRecoilState(walletCardReloadState);
  const matriculas = useRecoilValue(matriculasByResponsavelResponseState);

  const handleInputChange = (event) => {
    let {name, value} = event.target;

    if (name === 'cardholderName') {
      value = (String(value)).replace(new RegExp("[^a-zA-ZáéíóúÁÉÍÓÚàÀâÂãÃêÊíÍóÓôÔúÚçÇâêîôûÂÊÎÔÛäëïöüÄËÏÖÜñÑ ]"), "")
      value = String(value).slice(0, 26)
    }

    setCardValues({
      ...cardValues,
      [name]: value,
    });
  }

  const handleChangeCVV = (event) => {
    const {name, value} = event.target;
    if (value.length > 4) {
      event.target.value = value.slice(0, 4);
      return;
    }
    setCardValues({
      ...cardValues,
      [name]: value.replace(/[^0-9]/g, ''),
    });
  }

  const handleChangeMonth = (event) => {
    const {name, value} = event.target;
    let formated = value !== '0' && value !== ''
      ? String(value).padStart(2, '0').replace(/[^0-9]/g, '')
      : value.replace(/[^0-9]/g, '');

    if (value.startsWith('0') && value.length >= 2) {
      formated = formated.slice(1);
    } else if (value.length >= 2 && !value.startsWith('0')) {
      event.target.value = value.slice(0, 2);
      formated = value.slice(0, 2);
    }
    if (Number(formated) > 12) {
      event.target.value = '12';
      formated = '12';
    }

    if (formated.length < 2) {
      formated = formated.padStart(2, '0');
    }

    setCardValues({
      ...cardValues,
      [name]: formated,
    });
  }

  const handleChangeYear = (event) => {
    const {name, value} = event.target;
    if (value.length > 2) {
      event.target.value = value.slice(0, 2);
      return;
    }
    setCardValues({
      ...cardValues,
      [name]: value.replace(/[^0-9]/g, ''),
    });
  }

  const handleInputFocus = (focused = '') => () => {
    setFocused(focused)
  }

  const handleInputBlur = () => {
    setFocused('');
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isSaving) return ;

    setIsSaving(true);

    const cardNumber = cardValues.cardNumber.replace(/\D/g, '').replaceAll(" ", "");
    const cardholderIdentification = cardValues.cardholderIdentification.replace(/\D/g, '').replaceAll(" ", "");
    const duplicatedNewCard = !!creditCards.find(isDuplicatedNewCard(cardNumber));
    const duplicatedTokenizedCard = !!creditCards.filter(isTokenizedCard)
      .find(isDuplicatedTokenizedCard(cardNumber, cardValues));

    if (duplicatedNewCard || duplicatedTokenizedCard) {
      setErrors(['Cartão já cadastrado']);
      mensagem.ExibeMensagem('Cartão já cadastrado', '2')
      setIsSaving(false);
      return false;
    }

    try {
      let matriculaID = '00000000-0000-0000-0000-000000000000';

      if (matriculas?.value[0]?.id) {
        matriculaID = matriculas?.value[0]?.id;
      } else {
        const idResponsavelCookie = sessionStorage.getItem("@SMO20/idResponsavel");
        const result = await _matricula.GetbyMatriculasByResponsavel(idResponsavelCookie, false);
        if (result.value !== undefined && result.value.length > 0) {
          matriculaID = result.value[0].id;
        }
      }

      const resp = await _walletService.cartaoSalvar({
        ...cardValues,
        cardNumber,
        cardholderIdentification,
        sellerId: sessionStorage.getItem('@SMO20/unidade_Id'),
          customerId: sessionStorage.getItem('@SMO20/idResponsavel'),
          matriculaId: sessionStorage.getItem('idmatricula') ?? matriculaID
      })

      setIsSaving(false);

      if (resp.ok) {
        const card = await resp.json();

        setCreditCards([
          ...creditCards,
          card
        ])

        setCardValues(defaultValues);
        setErrors([]);
        mensagem.ExibeMensagem(`Cartão final ${card?.lastFourDigits} cadastrado com sucesso!`, '0')

        setReload(true);

        if (onCancel) onCancel() ; //volta para a tela de listagem

        return true;
      } else {
        const msg = await resp.text();
        mensagem.ExibeMensagem(msg, '2')
      }
    } catch (e) {
      console.error(e);
      mensagem.ExibeMensagem(e, '2')
    }

    return false;
  }

  return (
    <span>
      <span data-dd-privacy="hidden">
        <Card
          number={cardValues.cardNumber}
          name={cardValues.cardholderName}
          expiry={`${cardValues.expirationMonth}/${cardValues.expirationYear}`}
          cvc={cardValues.securityCode}
          acceptedCard={acceptedCards}
          placeholders={{name: 'Nome impresso no cartão'}}
          locale={{valid: 'BR'}}
          focused={focused}
          callback={({issuer}) => {
            if ((cardValues.brand === '' && issuer !== 'unknown') || (cardValues.brand !== issuer && issuer !== 'unknown')) {
              setBrand(issuer);
            }
          }}
        />
      </span>

      <form
        ref={formRef}
        onSubmit={onSubmit}
      >
        <Container className="mt-3">
          <Row className="mb-1">
            <Col>
              <label htmlFor="cardholderName">
                Nome do titular (Nome que está no cartão)
              </label>
              <input
                type="text"
                className={classNames(
                  'form-control',
                  formValidacao.cssTexto([], cardValues.cardholderName)
                )}
                id="cardholderName"
                name="cardholderName"
                autoComplete="cc-given-name"
                value={cardValues.cardholderName}
                max={26}
                maxLength={26}
                onChange={handleInputChange}
                onFocus={handleInputFocus('name')}
                onBlur={handleInputBlur}
                required
              />
            </Col>

            <Col>
              <label htmlFor="cardholderIdentification">
                Documento do titular (CPF)
              </label>
              <InputMask
                type="text"
                className={classNames(
                  'form-control',
                  cardValues.cardholderIdentification.length < 11 && 'validError',
                  formValidacao.cssCPFCNPJ([], cardValues.cardholderIdentification)
                )}
                id="cardholderIdentification"
                name="cardholderIdentification"
                value={cardValues.cardholderIdentification}
                onChange={handleInputChange}
                mask={cardholderIdentification_mask}
                required
              />
            </Col>
          </Row>

          <Row className="mb-1">
            <Col>
              <label htmlFor="cardNumber">
                Número do cartão
              </label>
              <InputMask
                type="text"
                className={classNames(
                  'form-control',
                  formValidacao.cssCartao(errors, cardValues.cardNumber)
                )}
                id="cardNumber"
                name="cardNumber"
                value={cardValues.cardNumber}
                autoComplete="cc-number"
                onChange={handleInputChange}
                onFocus={handleInputFocus('number')}
                onBlur={handleInputBlur}
                mask="9999 9999 9999 9999"
                required
              />
            </Col>

            <Col>
              <Row>
                <Col className="m-0 p-0 pr-2">
                  <label htmlFor="expirationMonth">
                    Mês
                  </label>
                  <input
                    type="number"
                    className={classNames(
                      'form-control',
                      (Number(cardValues.expirationMonth) < 1 || Number(cardValues.expirationMonth) > 12)
                        ? 'validError'
                        : 'validOk',
                    )}
                    maxLength={2}
                    id="expirationMonth"
                    name="expirationMonth"
                    autoComplete="cc-exp-month"
                    min="1"
                    max="12"
                    value={cardValues.expirationMonth}
                    placeholder="MM"
                    onChange={handleChangeMonth}
                    onFocus={handleInputFocus('expiry')}
                    onBlur={handleInputBlur}
                    required
                  />
                </Col>

                <Col className="m-0 p-0">
                  <label htmlFor="expirationYear">
                    Ano
                  </label>
                  <input
                    type="number"
                    className={classNames(
                      'form-control',
                      (Number(cardValues.expirationYear) < 10 || Number(cardValues.expirationYear) > 50)
                        ? 'validError'
                        : 'validOk',
                    )}
                    id="expirationYear"
                    maxLength={2}
                    min="10"
                    max="50"
                    name="expirationYear"
                    autoComplete="cc-exp-year"
                    value={cardValues.expirationYear}
                    placeholder="AA"
                    onChange={handleChangeYear}
                    onFocus={handleInputFocus('expiry')}
                    onBlur={handleInputBlur}
                    required
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col>
              <label htmlFor="securityCode">
                Código de segurança
              </label>
              <input
                type="number"
                className={classNames(
                  'form-control',
                  formValidacao.cssCVV(
                    [],
                    cardValues.securityCode
                  )
                )}
                id="securityCode"
                name="securityCode"
                autoComplete="cc-csc"
                value={cardValues.securityCode}
                onChange={handleChangeCVV}
                onFocus={handleInputFocus('cvc')}
                onBlur={handleInputBlur}
                required
              />
            </Col>

            <Col className="smo-wallet-modal-brands-col">
              <div className="smo-wallet-modal-brands-container">
                <p>Bandeiras aceitas:</p>
                <div className="smo-wallet-modal-brands">
                  {Object.values(BRANDS_CONFIG(false)).map((brand) => (
                    <img
                      key={brand.img}
                      id={brand.idComponent}
                      src={brand.img}
                      alt='brand'
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {(errors.length > 0) && (
          <div className="alert alert-danger mt-3">
            <ul className="m-3">
              {errors.map((error) => (
                <li className="m-1" key={error}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <Row className="mt-5">
          <Col>
            {creditCards.length > 0 ? (
              <button
                className="btn btn-secondary"
                onClick={onCancel}
                type="button"
                disabled={isSaving}
              >
                Voltar
              </button>
            ) : (
              <button
                className="btn btn-secondary"
                onClick={onClose}
                type="button"
                disabled={isSaving}
              >
                Cancelar
              </button>
            )}
          </Col>

          <Col
            className="text-right"
          >
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSaving}
            >
              {isSaving ? <LoadingAnimation/> : "Adicionar"}
            </button>
          </Col>
        </Row>
      </form>
    </span>
  )
}

export default WalletSaveCard;
