import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import {CircularProgress, Typography} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'

import {formatOwnName} from 'utils/formatString.js'
import useStyles from './style'
import {
  hasLoadedRegistrationExpiredSelector,
  registrationExpiredDataSelector,
  regristrationExpiratedSelected
} from 'modules/Negotiation/Finance/selectors/registrations'
import {accessIdSelector, appIdSelector} from 'modules/Login/selectors/user'
import GenderAvatar from 'components/gender'
import {FormattedMessage} from 'react-intl'
import {useRecoilValue, useSetRecoilState} from "recoil";
import {matriculasByResponsavelResponseState} from "../../../../../recoil/atoms/matriculasByResponsavelResponseState";
import * as _matricula from "../../../../../service/matricula-service";
import {selectedMatriculaState} from "../../../../../recoil/atoms/selectedMatriculaState";
import {getAll} from "../../../../Finance/services/unity";
import {unidadeGetResponseState} from "../../../../../recoil/atoms/unidadeGetResponseState";

export const StudentListNegotiation = (props) => {
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState)
  const setUnidade = useSetRecoilState(unidadeGetResponseState)
  const selectedMatricula = useRecoilValue(selectedMatriculaState)
  const [active, setActive] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(registrationExpiredDataSelector)
  const registrationSelected = useSelector(regristrationExpiratedSelected)
  const isLoadedRegistrationSelected = useSelector(hasLoadedRegistrationExpiredSelector)
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const matriculasByResponsavelResponse = useRecoilValue(matriculasByResponsavelResponseState)
  const setMatriculasByResponsavelResponse = useSetRecoilState(matriculasByResponsavelResponseState)

  React.useEffect(() => {
    if (data) {
      const {
        registrationId,
        studentName
      } = data[active]

      if (!matriculasByResponsavelResponse)
      {
        _matricula.GetbyMatriculasByResponsavel(sessionStorage.getItem('@SMO20/idResponsavel'), false)
          .then(resp => {
            const {
              value = []
            } = resp;
            let mat = (value || []).find(a => a?.codigoMatriculaErp === registrationId)

            if (!mat) {
              mat = (value || []).find(a => String(a?.estudante_Name ).toLowerCase() === String(studentName).toLowerCase())
            }

            if (mat)
            {
              setSelectedMatricula(mat)
            }
            setMatriculasByResponsavelResponse(resp);
          });
      } else {
        const {
          value = []
        } = matriculasByResponsavelResponse;

        let mat = (value || []).find(a => a?.codigoMatriculaErp === registrationId)

        if (!mat) {
          mat = (value || []).find(a => String(a?.estudante_Name ).toLowerCase() === String(studentName).toLowerCase())
        }

        if (mat)
        {
          setSelectedMatricula(mat)
        }
      }
    }
  }, [active])

  useEffect(() => {
    if (selectedMatricula) {
      const { unidade_Id } = selectedMatricula
      getAll(unidade_Id).then((response) => {
        if (response && response.length) {
          const unity = response.find(r => r.id === unidade_Id)
          if (unity) {
            setUnidade(unity)
          }
        }
      })
    }
  }, [selectedMatricula])

  React.useEffect(() => {}, [registrationSelected, isLoadedRegistrationSelected, dispatch, appid, accessId])

  if (!isLoadedRegistrationSelected) {
    return <CircularProgress className={classes.studantLoading} />
  }

  if (data.length === 0) {
    return <Typography gutterBottom
      component='p' color={'textPrimary'}>
      <FormattedMessage id='finance-negotiation-comp-student-not-found' />
    </Typography>
  }

  const selectRegistration = (student, key) => {
    props.selectRegistration(student)
    setActive(key)
  }

  return (
    <Grid container spacing={2}>
      {data.map((student, i) => {
        const { studentName, gender, unityName } = student
        return (
          <Grid item xs={12} sm={6} md={6} lg={3} key={i} onClick={() => { selectRegistration(student, i) }}>
            <div className={classNames(classes.studantInfo, active === i && classes.studantInfoSelected)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <GenderAvatar gender={gender} className={classes.studantAvatar} />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.studantContent}>
                    <Typography gutterBottom color={'textSecondary'} className={classes.studantName}>
                      {formatOwnName(studentName)}
                    </Typography>
                    <Typography variant='caption' gutterBottom color={'textSecondary'} >
                      {unityName}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>)
      })}
    </Grid >)
}

export default StudentListNegotiation;