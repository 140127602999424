import CardSelection from "./CardSelection";
import {Button} from "react-bootstrap";
import React from "react";

function WalletSelectionPreview(
  {
    card,
    onClick = () => {},
    label = "Clique aqui para selecionar um cartão"
  }
) {

  const handleClick = () => {
    if (onClick) onClick();
  }

  return (
    <React.Fragment>
      {card ? (
        <>
          <p>
            <strong>Cartão selecionado:</strong>
          </p>
          <CardSelection
            card={card}
            onClickChange={handleClick}
            buttonLabel="Alterar cartão"
            showRemove={false}
            type="row"
          />
        </>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={handleClick}
                type="button"
              >
                <svg
                  className="mr-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z"/>
                </svg>
                {label}
              </Button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default WalletSelectionPreview;
